<template>
    <div v-bind="$attrs">
        <label for="table-search" class="tw-sr-only">{{
            $t("assignDriver.search")
        }}</label>
        <div class="tw-relative tw-mt-1 tw-flex">
            <div
                class="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3 tw-pointer-events-none"
            >
                <svg
                    class="tw-w-5 tw-h-5 tw-text-gray-500 dark:tw-text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
            </div>
            <input
                :value="value"
                type="text"
                id="table-search"
                class="tw-border-l tw-border-t tw-border-b tw-text-gray-900 tw-text-sm tw-rounded-tl tw-rounded-bl  tw-outline-none tw-block tw-w-full tw-pl-10 tw-p-1.5"
                :placeholder="placeholder"
								@input="onInput"
								@keypress.enter="onClickSearch"
								ref="inputSearch"
            />
						<button 
							@click.prevent="onClickSearch"
							class="tw-bg-blue-500 tw-px-3 tw-rounded-tr tw-rounded-br tw-text-white">{{ $t('search') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "searchBox",
    props: ["placeholder", "value"],
    model: {
        event: "input"
    },
    methods: {
        onInput(event) {
            this.$emit("input", event.target.value);
        },
				onClickSearch(){
					this.$emit('on-search', this.value)
					this.$refs.inputSearch.focus()
				}
    }
};
</script>
